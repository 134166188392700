/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {HrefLangManager} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/contact/'], ['en', 'https://www.hear.com/contact/'], ['en-US', 'https://www.hear.com/contact/'], ['en-CA', 'https://ca.hear.com/contact/']]
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "arrange-a-callback--quick-free-and-uncomplicated",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#arrange-a-callback--quick-free-and-uncomplicated",
    "aria-label": "arrange a callback  quick free and uncomplicated permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Arrange a callback – Quick, free and uncomplicated"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "How it works: Fill out our ", React.createElement(_components.a, {
    href: "https://ca.cdn.hear.com/full_page_questionnaire/",
    className: "c-md-a"
  }, "questionnaire"), " and one of our hearing aid experts will contact you by phone – for free and without obligation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✔ Competent consultation by hearing aid experts"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✔ Hearing aids from all leading manufacturers for competitive prices"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✔ Find out which hearing aid fits you best"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
